.overlay-bottom {
    --overlay-size: 10%;
    --overlay-space-left: 10%;
}

.overlay-content,
.overlay-trigger {

    position: fixed;
    bottom: 0;
}

.overlay-trigger {
    width: 100%;
    left: 0;
    height: calc( 1.5 * var(--overlay-size));
}

.overlay-content {
    width: calc( 100% - var(--overlay-space-left));
    left: var(--overlay-space-left);
    height: var(--overlay-size);
    transition: bottom 0.2s;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
}

.overlay-bottom>.overlay-content {
    bottom: calc( -1 * var(--overlay-size));
}

.overlay-bottom.visible>.overlay-content {
    bottom: 0;
}

.overlay-trigger {
    /* z-index: 2; */
}

/* 

left-overlay{
    background-color: red;
}
left-overlay > *{
    background-color: red;
}



right-overlay{
    background-color: greenyellow;
}
right-overlay > *{
    background-color: greenyellow;
}



bottom-overlay{
    background-color: lightskyblue;
}
bottom-overlay > *{
    background-color: lightskyblue;
}



top-overlay{
    background-color: silver;
}
top-overlay > *{
    background-color: silver;
} 

*/

