@import url("./reset.css");
@import url("./tabs.css");
@import url("./btns.css");
.row {
  display: flex;
  align-items: center;
  justify-content: stretch;
}
.column {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
}
.row button,
.row select {
  align-self: stretch;
}

button svg {
  width: 20px;
  height: 20px;
  /* max-width: 30px; */
}

button {
  cursor: pointer;
}

button:disabled{
  opacity: 0.4;
  cursor: not-allowed;

}

#widget-gsi div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clickable{
  cursor: pointer;
}