.coordinates{
    pointer-events: none;;
    display:flex;
    flex-direction: column;
    align-items: stretch;
}

.coordinates > div{
    display:flex;
    justify-content: space-between;
}
.coordinates .label{
    margin-right: 20px;
}