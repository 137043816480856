.descartes-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  font-size: 1.2em;
}

.descartes-controls .btn, .descartes-controls .button {
  min-width: 50px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.descartes-controls .control.active {
  opacity: 1 !important;
  background-color: rgba(200, 100, 100, 1) !important;
}
