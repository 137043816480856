.btn {
  min-width: 1.5em;
  /* height: 1.5em; */
  /* border-radius: 50%; */
  background-color: var(--editable-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.btn:hover {
  background-color: darkgrey;
  color: black;
}
