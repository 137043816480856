.context-menu {
  cursor: default;
  min-width: 150px;
  min-height: 50px;
  z-index: 500;
  position: fixed;
  background-color: rgb(50, 50, 50);
  font-size: 1em;
  color: darkgrey;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.menu-context {
  height: 10px;
  font-size: 0.6em;
}
.menu-context .clickable {
  cursor: pointer;
  opacity: 0.8;
}
.menu-context .clickable:hover {
  cursor: pointer;
  opacity: 1;
}

.context-menu {
  transition: height 1s ease-out;
  transition: width 1s ease-out;
}

.menu-title {
  display: flex;
  justify-content: center;
  padding: 4px 10px;
  padding-bottom: 8px;
  margin-top: 0;
  border-bottom: 1px solid white;
}

.action-list {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.action-list .group::after {
  content: ">";
  right: 0;
}

.action-list > div {
  cursor: pointer;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}

.action-list > div:hover {
  background-color: rgb(22, 22, 22);
}
