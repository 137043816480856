.carte-widgets .error {
  color: darkred !important;
  background-color: rgba(138, 0, 0, 0.2) !important;
}

.carte-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  margin: 3px 0;
  font-size: 1.2rem;
}

.carte-controls > .name {
  /* flex: 1; */
  display: block;
  min-width: 70px;
  flex: 1;
}

.carte-widgets {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.objects {
  max-height: 100%;
}

.spinning {
  animation: linear spin-animation 2s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
