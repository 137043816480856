body {
  overflow: hidden;
}
header > .row {
  flex-wrap: wrap;
}

body {
  height: 100vh;
  /* overflow: hidden; */
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

#control-panel ~ #app #simple-tool-controls {
  z-index: 500;
  background-color: white;
}

#control-panel ~ #simple-tool-controls {
  z-index: 500;
  background-color: white;
}