.tab {
  flex: 1;
  padding: 10;
  max-width: 200px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.tab.active {
  background-color: lightgreen;
}

.tabs{
}