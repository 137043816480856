.btn {
  height: 100%;
}

.disabled .btn{
  opacity: 0.5;
}

.btn,
button {
  --color: black;
  --color: #00000070;
  --hover-color: #000000;

  min-width: 50px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;

  color: var(--color);
  padding: 4px 6px;
  font-family: sans-serif;
  cursor: pointer;
  font-weight: bold;
  margin: 2px;
}
.btn:hover,
button:hover {
  background-color: #ffe300;
  color: var(--hover-color);
}

pre {
  white-space: break-spaces;
  overflow-wrap: break-word;
  overflow: auto;
}

.row.object {
  justify-content: center;
  padding: 5px;
  cursor: pointer;
}

.selected {
  background-color: #00ff00;
  color: black;
  font-weight: bold;
}

.column {
  display: flex;
  flex-direction: column;
}
