.details {
  display: flex;
  flex-direction: column;
  background: black;
  color: white;
  overflow: hidden;
  margin-top: 60px;
  height: calc(100vh - 120px);
  /* padding: 30px 0; */
  background: rgba(0, 0, 0, 0.7);
  overflow: auto;
  overflow: hidden;
}
.details > * {
  width: 300px;
}
.details > section {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  max-height: 100%;
}

.details .tabs {
  flex-wrap: wrap;
  border-bottom: 1px solid black;
  justify-content: center;
}

.details .tabs > div {
  padding: 5px;
  cursor: pointer;
}

.details-content {
  overflow: auto;
}
