.notifications-container {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  top: 50px;
  z-index: 300;
}

.notifications {
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.notification {
  padding: 0 5px;
  height: 1px;
  animation: growingNotification 100ms ease forwards;
  margin: 2px;
  background-color: #222;
  color: lightgrey;
  border: 1px solid white;
  border-radius: 3px;
  /* flex: 1; */
  width: 100%;
  z-index: 300;
  /* transition: all 500ms linear; */
  max-width: 80%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  padding-left: 10px;
}

@keyframes growingNotification {
  to {
    height: 100%;
    min-height: 40px;
  }
}

.notification {
  font-size: unset !important;
}
.notification.error {
  background-color: darkred;
}
.notification.warning {
  background-color: darkorange;
}

.notification.info {
  background-color: darkblue;
}

.notification.success {
  background-color: limegreen;
  color: black;
  border-color: black;
}
.notification.trace {
  background-color: dimgray;
}

@media (min-width: 1200px), screen and (orientation: landscape) {
  .notification {
    max-width: 50%;
  }
}


.notification .theme{
  margin: 0 5px;
}


