.journal-controls > .row {
  flex: 1;
  justify-content: center;
}






.journal-entry > .message {
  flex: 6;
  text-align: start;
}
.journal-entry > .timestamp {
  flex: 1;
  text-align: end;
}
.journal-entry > span {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 5px;
  flex: 1;
  text-align: center;
}

/* tbody td {
  padding: 30px;
} */

.journal-entry{
  cursor: context-menu ;
  padding:3px;
}

.journal-entry:hover{
  background-color: lightgreen !important;
}
.journal-entry:nth-child(odd) {
    background-color: #d8d8da;
    /* color: #fff; */
}
