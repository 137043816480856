.known-selection{
    display:flex;
}
.known-selection > *{
    flex:1;
    text-align: center;
}

.known-selection > .btn{
    color:white;
}

.known-selection > .btn:hover{
    color:black;
}