.modal {
  top: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.modal-background {
  width: 100vw;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: none;
}

.modal-content {
  background-color: white;
  padding: 10px;
  margin: 50px;
  z-index: 200;
  width: calc(100vw - 100px);
  overflow: hidden;
  overflow-y: auto;
}

.modal-content .btns {
  flex: 1;
  justify-content: flex-end;
}

.variable {
  margin: 10px;
  flex-wrap: wrap;
}

.variable > * {
  flex: 1;
  margin: 0 10px;
  display: block;
  width: 20vw;
  overflow: hidden;
}
.variable > .name {
  font-weight: bold;
}
.variable .raw {
  min-width: 300px;
}

