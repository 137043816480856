#scale-legends {
  position: absolute;
  left: 0;
  bottom: 50px;
  pointer-events: none;

  display: flex;
  flex-direction: row;
}

.legend-title {
  margin: 0 10px;
}
