body,html{
    margin:0;
    padding:0;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
}
body {
  display: flex;
  flex-direction: column;
}
