.box-select{
    position: absolute;
	display: none;
	background: rgba(123, 70, 166, 0.4);
	border: 2px solid rgba(123, 70, 166, 0.8);
	z-index: 99;
	border-radius: 2px; 
}

.box-select-container{
    position: relative;
	/* user-select: none; */
}