.player {
    font-size: 1.5em;
    display: flex;
    align-items: center;
    flex: 1;
}

.player:focus {
    outline: none;
}

.playpause {
    font-size: 1.8em;
    padding: 5px;
}

.playpause {}

.controls {
    margin: 0 20px;
    display: flex;
    align-items: center;
}

.controls>div {
    cursor: pointer;
    margin: 0 10px;
}

.timeline{
    flex: 1;
    display:flex;
    flex-direction:column;
    align-items:stretch;
    justify-content: stretch;
    position:relative;
    height: 100%;
}
.timeline input{
    z-index: 5;
}
.timeline > *{
    flex:1;
}
.chrono{
    min-width: 200px;
    text-align: right;
}
.timeline .overview{
    pointer-events: none;
    position:absolute;
    opacity: 0.5;
}